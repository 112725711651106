import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

document.addEventListener('DOMContentLoaded', () => {
  // Initialize Uppy
  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 10,
      allowedFileTypes: ['image/*', '.pdf', '.docx', '.xlsx', '.txt'] // Allowed file types
    },
    autoProceed: false // Manual upload initiation
  });

  // Add the Dashboard plugin
  uppy.use(Dashboard, {
    inline: true,
    target: '#uppy-dashboard',
    showRemoveButtonAfterComplete: false, // Show remove button for uploaded files
    proudlyDisplayPoweredByUppy: false,
    disableThumbnailGenerator: false, // Enable thumbnail generation
    hideProgressAfterFinish: true
  });

  // ActiveStorage plugin for Rails file upload
  uppy.use(ActiveStorageUpload, {
    directUploadUrl: '/rails/active_storage/direct_uploads'
  });

  // Track files being removed
  const deletedFilesInput = document.querySelector('#deleted-files');
  uppy.on('file-removed', (file) => {
    if (file.isRemote) {
      // Track removed files
      const deletedFiles = JSON.parse(deletedFilesInput.value || '[]');
      deletedFiles.push(file.id);
      deletedFilesInput.value = JSON.stringify(deletedFiles);
    }
  });

  // Store successfully uploaded files in a hidden input
  const uploadedFilesInput = document.querySelector('#uploaded-files');
  uppy.on('complete', (result) => {
    const uploadedFileSignedIds = result.successful.map((file) => file.response.signed_id);
    uploadedFilesInput.value = JSON.stringify(uploadedFileSignedIds);
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const deletedFilesInput = document.getElementById('deleted-files');

  // Add event listener to all remove buttons
  document.querySelectorAll('.remove-file').forEach(button => {
    button.addEventListener('click', () => {
      const fileId = button.dataset.fileId;
      const fileElement = document.getElementById(`file-${fileId}`);

      // Add file ID to deleted files input
      let deletedFiles = JSON.parse(deletedFilesInput.value || '[]');
      if (!deletedFiles.includes(fileId)) {
        deletedFiles.push(fileId);
      }
      deletedFilesInput.value = JSON.stringify(deletedFiles);

      // Remove file visually
      fileElement.remove();

    });

  });

});