document.addEventListener('DOMContentLoaded', function () {
    const countryDropdown = document.querySelector('.country_select');
    const countryCodeDropdown = document.querySelector('.select2-country-code');
    const phoneNumberField = document.querySelector('.phone_number_field');
    function updateCountryCodeAndPlaceholder() {
        const selectedCountry = countryDropdown.value;
        if (countryData[selectedCountry]) {
            const countryCode = countryData[selectedCountry].code;
            const placeholder = countryData[selectedCountry].examplePlaceholder;
            // Update country code dropdown
            [...countryCodeDropdown.options].forEach(option => {
                if (option.value === countryCode) {
                    option.selected = true;
                    $(countryCodeDropdown).val(countryCode).trigger('change');
                }
            });
            phoneNumberField.placeholder = placeholder;
        }
    }
    updateCountryCodeAndPlaceholder();
    countryDropdown.addEventListener('change', updateCountryCodeAndPlaceholder);
    function updatePhoneNumberPlaceholder() {
        const selectedCountryCode = $('.select2-country-code').val(); // Get the selected country code from the dropdown

        for (let country in countryData) {
            if (countryData[country].code === selectedCountryCode) {
                $('.phone_number_field').attr('placeholder', countryData[country].examplePlaceholder);
                break;
            }
        }
    }
    $('.select2-country-code').on('change', function () {
        updatePhoneNumberPlaceholder();
    });

    updatePhoneNumberPlaceholder();
});